<script>
import { togglePasswordVisibility } from '@/mixins/form/toggle-password-visibility';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { validationMixin } from '@/mixins/form/validation';
import { authComputed } from '@/state/helpers';
import Multiselect from '@vueform/multiselect';

export default {
  name: 'personal-data-tab',
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  mixins: [togglePasswordVisibility, validationMixin],
  data() {
    return {
      loading: false,
      base_currency: null,
      passwordForm: {
        old_password: null,
        new_password: null,
        new_password_confirmation: null,
      },
      currencies: [],
      passwordFields: {
        old_password: 'password',
        new_password: 'password',
        new_password_confirmation: 'password',
      },
      profile: {},
      loadingCurrencies: false,
    };
  },
  computed: {
    ...authComputed,
  },
  methods: {
    loadCurrencies() {
      this.loadingCurrencies = true;
      return this.axios
        .get(`/currencies`)
        .then(({ data }) => {
          this.currencies = data.map((currency) => {
            return {
              value: currency.id,
              text: currency.key,
            };
          });
        })
        .finally(() => {
          this.loadingCurrencies = false;
        });
    },
    loadProfile() {
      this.loading = true;

      this.axios
        .get('/profile')
        .then(({ data }) => {
          this.profile = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitChangePassword(values, actions) {
      this.loading = true;

      this.axios
        .patch('/auth/password', this.passwordForm)
        .then(() => {
          this.$notify({
            type: 'success',
            text: this.$t('Profile.Settings.PasswordChanged'),
          });
          this.resetForm();
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, { values, actions });

          if (!response?.data?.error?.fields && response?.data?.error?.message) {
            actions.setFieldError('old_password', response.data.error.message);
          }
        })
        .finally(() => (this.loading = false));
    },
    resetForm() {
      this.passwordForm = {
        old_password: null,
        new_password: null,
        new_password_confirmation: null,
      };

      this.$refs.form.resetForm();
    },
    saveBaseCurrency() {
      this.$nextTick(() => {
        this.loadingCurrencies = true;

        this.axios
          .patch(`auth/base-currency/${this.base_currency}`)
          .then(() => {
            this.$notify({
              type: 'success',
              text: this.$t('Profile.Settings.CurrencyChanged'),
            });
          })
          .finally(() => {
            this.loadingCurrencies = false;
          });
      });
    },
  },
  mounted() {
    this.base_currency = this.currentUser.base_currency.id;
    this.loadProfile();
    this.loadCurrencies();
  },
};
</script>

<template>
  <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
    <b-card-text>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <h4 class="card-title mb-4">{{ $t('Profile.Settings.Title') }}</h4>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group class="mb-3 text-muted" :label="$t('Profile.PersonalData.BaseCurrency')">
                    <Multiselect
                      v-model="base_currency"
                      :options="currencies"
                      :label="'text'"
                      :rtl="$i18n.locale === 'ar'"
                      :loading="loadingCurrencies"
                      :can-clear="false"
                      :can-deselect="false"
                      @change="saveBaseCurrency"
                    />
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <Form ref="form" @submit="submitChangePassword">
                    <h4 class="font-size-14 mb-3 mt-5">{{ $t('Profile.Settings.ChangeAccountPassword') }}</h4>

                    <Field
                      name="old_password"
                      rules="required"
                      :label="$t('Profile.Settings.CurrentPassword')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group
                        class="mb-3 text-muted"
                        :label="$t('Profile.Settings.CurrentPassword')"
                        label-for="old_password"
                      >
                        <b-input-group>
                          <b-form-input
                            id="old_password"
                            v-model="passwordForm.old_password"
                            :type="passwordFields.old_password"
                            class="form-control-merge"
                            :class="{ 'is-invalid': errors.length }"
                            v-bind="field"
                          />
                          <b-input-group-append is-text :class="{ 'is-invalid': errors.length }">
                            <i
                              class="mdi cursor-pointer text-muted font-size-14"
                              :class="[passwordIconClasses.old_password]"
                              @click="togglePasswordVisibility('old_password')"
                            ></i>
                          </b-input-group-append>
                        </b-input-group>

                        <ErrorMessage name="old_password" class="invalid-feedback d-block" />
                      </b-form-group>
                    </Field>

                    <Field
                      name="new_password"
                      rules="required|min:8"
                      :label="$t('Form.Password')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3 text-muted" :label="$t('Form.Password')" label-for="new_password">
                        <b-input-group>
                          <b-form-input
                            id="new_password"
                            v-model="passwordForm.new_password"
                            :type="passwordFields.new_password"
                            class="form-control-merge"
                            :class="{ 'is-invalid': errors.length }"
                            v-bind="field"
                          />
                          <b-input-group-append is-text :class="{ 'is-invalid': errors.length }">
                            <i
                              class="mdi cursor-pointer text-muted font-size-14"
                              :class="[passwordIconClasses.new_password]"
                              @click="togglePasswordVisibility('new_password')"
                            ></i>
                          </b-input-group-append>
                        </b-input-group>

                        <ErrorMessage name="new_password" class="invalid-feedback d-block" />
                      </b-form-group>
                    </Field>

                    <Field
                      name="new_password_confirmation"
                      rules="required|confirmed:@new_password"
                      :label="$t('Profile.Settings.RepeatPassword')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group
                        class="mb-3 text-muted"
                        :label="$t('Profile.Settings.RepeatPassword')"
                        label-for="new_password_confirmation"
                      >
                        <b-input-group>
                          <b-form-input
                            id="new_password_confirmation"
                            v-model="passwordForm.new_password_confirmation"
                            :type="passwordFields.new_password_confirmation"
                            class="form-control-merge"
                            :class="{ 'is-invalid': errors.length }"
                            v-bind="field"
                          />
                          <b-input-group-append is-text :class="{ 'is-invalid': errors.length }">
                            <i
                              class="mdi cursor-pointer text-muted font-size-14"
                              :class="[passwordIconClasses.new_password_confirmation]"
                              @click="togglePasswordVisibility('new_password_confirmation')"
                            ></i>
                          </b-input-group-append>
                        </b-input-group>

                        <ErrorMessage name="new_password_confirmation" class="invalid-feedback d-block" />
                      </b-form-group>
                    </Field>

                    <button type="submit" class="btn btn-dark btn-sm w-lg" ref="submitBtn">
                      {{ $t('Profile.Settings.UpdateBrandPassword') }}
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </b-card-text>
  </b-overlay>
</template>
